import { useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db as firebaseDb } from "../firebase";

const useFirebaseData = (isPublic, placeId) => {
  const [data, setData] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null); // new state variable to track the current user's ID

  const auth = getAuth();
  const { currentUser } = auth;

  useEffect(() => {
    if (currentUser) {
      setCurrentUserId(currentUser.uid);
    } else {
      setCurrentUserId(null);
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let docRef;

        if (isPublic) {
          docRef = doc(firebaseDb, "public", placeId);
        } else {
          if (!currentUserId) return; // Return early if there is no current user ID
          docRef = doc(firebaseDb, "users", currentUserId, "places", placeId);
        }

        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const infoRef = collection(docRef, "info");
          const infoSnap = await getDocs(infoRef);

          const questionsRef = collection(docRef, "questions");
          const questionsSnap = await getDocs(questionsRef);

          const imagesRef = collection(docRef, "photos");
          const imagesSnap = await getDocs(imagesRef);

          const infoData = infoSnap.docs.map((doc) => doc.data());
          const questionsData = questionsSnap.docs.map((doc) => doc.data());
          const imagesData = imagesSnap.docs.map((doc) => doc.data());

          const placeData = {
            ...docSnap.data(),
            info: infoData,
            questions: questionsData,
            images: imagesData,
          };

          setData(placeData);
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (currentUserId || isPublic) {
      fetchData();
    }
  }, [isPublic, placeId, currentUserId]);

  return data;
};

export default useFirebaseData;
