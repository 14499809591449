import React, { useState, useEffect } from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { auth } from "../../firebase";
import { getTripSummary } from "../../api";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "",
    backgroundColor: theme.palette.background.default,
    minHeight: "100vh",
    paddingBottom: "80px",
    overflow: "auto"
  },
  
  title: {
    fontFamily: "Courier New",
    marginBottom: "1rem",
    color: theme.palette.primary.main,
  },
  subtitle: {
    marginBottom: "1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1rem",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    borderRadius: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

function Summary() {
  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [places, setPlaces] = useState([]);
  const [tripSummary, setTripSummary] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchPlaces(currentUser.uid);
      } else {
        setUser(null);
        setPlaces([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const handleShare = async () => {
    if (!navigator.share) {
      // If the navigator.share API is not available, do nothing
      return;
    }

    try {
      await navigator.share({
        title: "Trip summary",
        text: tripSummary,
      });
    } catch (err) {
      console.error("Error sharing:", err);
    }
  };
  const fetchPlaces = async (uid) => {
    const db = getFirestore();
    const placesRef = collection(db, "users", uid, "places");
    const placesSnapshot = await getDocs(placesRef);

    const fetchedPlaces = placesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setPlaces(fetchedPlaces);
  };

  const handlePlaceClick = async (uid, placeid) => {
    setLoading(true);
    const summary = await getTripSummary(uid, placeid);
    setTripSummary(summary.summary.content);
    setLoading(false);
  };

  return (
<Box className={classes.container} style={{ maxHeight: "calc(100vh - 20vh)" }}>
      <Typography variant="h5" className={classes.subtitle} align="center">
        Places Visited
      </Typography>
      <List className={classes.list}>
        {places.map((place) => (
          <ListItem
            key={place.id}
            className={classes.listItem}
            onClick={() => handlePlaceClick(user.uid, place.id)}
          >
            <ListItemText primary={place.name} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h6" className={classes.subtitle} align="center">
        Click on a place above to generate a trip summary!
      </Typography>
      {loading ? (
        <CircularProgress />
      ) : (
        <Typography variant="body1" align="center">
          {tripSummary}
        </Typography>

      )}              <button onClick={handleShare}>Share</button>

    </Box>
  );
}

export default Summary;
