const cityQuestions = [
    "Where's the best spot to take a selfie with the city skyline?",
    "If you were a local, where would you hang out?",
    "What's the wildest party spot in the city?",
    "What's the best hidden gem in the city?",
    "What's the funniest experience you've had in the city?",
    "If this city was a character in a movie, who would it be?",
    "What's the weirdest local tradition or festival you've heard of in this city?",
    "If you could teleport to one spot in the city right now, where would you go?",
    "Where's the best rooftop bar in the city?",
    "When's the best time of day to take a stroll around the city?",
    "What's the most beautiful building in the city?",
    "Where's the best place to watch the sunset in the city?",
    "What's the best park in the city?",
    "What's the most interesting historical fact about the city?",
    "What's the most unique aspect of the city's architecture?",
    "What's the vibe like in the city's nightlife scene?",
    "Where's the best local spot to catch a live music performance?",
    "What's the best way to experience the city's natural beauty?",
    "How would you describe the locals' attitude in this city?",
    "What's the most famous piece of street art in the city?",
    "What's the best street food to try in the city?",
    "What's the most scenic drive to take in the city?",
    "What's the best way to explore the city's art scene?",
    "What's the most unique museum in the city?",
    "What's the most interesting shop in the city?",
    "What's the best way to escape the crowds in the city?",
    "What's the most unusual hotel in the city?",
    "What's the best local festival to attend in the city?",
    "What's the best way to get off the beaten path in the city?",
    "What's the best view of the city from a high point?",
    "What's the best way to learn about the city's history?"
  ];
  
  export default cityQuestions;
  