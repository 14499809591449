import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import "./hidden.css";

const useStyles = makeStyles((theme) => ({
  button: {
    background: 'linear-gradient(45deg, #E0E0E0, #FFFFFF)',
    borderRadius: 3,
    border: 0,
    color: "Black",
    height: 48,
    fontFamily:"Courier New",
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    textTransform: "none",
    fontSize: 20,
    fontWeight: "Bold",
  },
  positionedMenu: {
    position: "fixed !important",
    top: 12,
    left: 12,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "fixed !important",

      top: "calc(100% - 60px)", // Move the menu to the bottom on mobile
    },
  }
}));

export default function PositionedMenu({ onClose, style }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleItemClick = (path) => {
    navigate(path);
    onClose && onClose(); // Close menu if onClose function is passed as prop
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="hide-on-small">
      <div className={`${classes.positionedMenu} hide-on-small`}>
      <Button
        id="demo-positioned-button"
        aria-controls={anchorEl ? "demo-positioned-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={anchorEl ? "true" : undefined}
        onClick={handleClick}
        className={classes.button}
      >
        Menu
      </Button>
      <Menu
        id="demo-positioned-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        style={style} // Add this line
      >
        {" "}
        <MenuItem onClick={() => handleItemClick("/logout")}>Home</MenuItem>
        <MenuItem onClick={() => handleItemClick("/profile")}>Profile</MenuItem>
        <MenuItem onClick={() => handleItemClick("/addplace")}>New Place</MenuItem>

      </Menu>
    </div>
    </div>
  );
}
