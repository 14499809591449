import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Dropzone from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { auth } from "../../firebase";
import { getStorage, ref, listAll, uploadBytes } from "firebase/storage";
import { doc, getFirestore, collection, setDoc } from "firebase/firestore";
import { useFirebaseApp } from "reactfire";
import "firebase/storage";
import { getDownloadURL } from "firebase/storage";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addDoc } from "firebase/firestore";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
    border: `1px dashed ${theme.palette.primary.main}`,
    transition: "border .3s ease-in-out",
    minHeight: "50vh", // Set a minimum height to center everything
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "",
    backgroundColor: "", // Updated background color
  },
  active: {
    borderColor: theme.palette.secondary.main,
  },
  icon: {
    fontSize: theme.typography.fontSize * 2,
    color: theme.palette.primary.light,
  },
  button: {
    marginTop: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.success.main,
    "&:hover": {
      backgroundColor: theme.palette.success.dark,
    },
  },
}));

function PhotoUploader({ onImagesUploaded, fetchAllImages }) {
  const classes = useStyles();

  const [files, setFiles] = useState([]);
  const { placeid } = useParams();
  const firebaseApp = useFirebaseApp();
  const firestore = getFirestore(firebaseApp);
  const storage = getStorage(firebaseApp);
  const uid = auth.currentUser.uid;

  const handleUpload = async () => {
    const uid = auth.currentUser.uid;
    const promises = files.map((file) => {
      const fileId = uuidv4();
      const filePath = `users/${uid}/places/${placeid}/photos/${fileId}.jpg`;

      const uploadTask = uploadBytes(ref(storage, filePath), file);

      return uploadTask.then(async () => {
        const fileUrl = await getDownloadURL(ref(storage, filePath));

        // Save the filename to Firestore
        const photoRef = await addDoc(
          collection(firestore, `users/${uid}/places/${placeid}/photos`),
          {
            filename: `${fileId}.jpg`,
            url: fileUrl,
            createdAt: new Date(),
          }
        );

        return photoRef;
      });
    });

    Promise.all(promises)
      .then(async () => {
        toast.success("All images have been uploaded successfully");
        setFiles([]);
        // Fetch the updated image URLs and pass them to the parent component
        onImagesUploaded();
      })
      .catch((error) => {
        toast.error(`Error uploading images: ${error.message}`);
      });
  };

  const handleDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",

          padding: "20px",
          minWidth: "100%",
        }}
      >
        <ToastContainer />
        <Dropzone onDrop={handleDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag and drop some photos here, or click to select photos</p>
              <ul>
                {files.map((file) => (
                  <li key={file.name}>
                    {file.name} - {file.size} bytes
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Dropzone>
        <Button
          variant="contained"
          color="secondary"
          className={classes.button}
          onClick={handleUpload}
          disabled={files.length === 0}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default PhotoUploader;
