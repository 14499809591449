import React, { useState, useEffect } from "react";
import { db, auth } from "../../firebase";
import { v4 as uuidv4 } from "uuid";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  onSnapshot,
  deleteDoc,
} from "firebase/firestore";
import { useParams } from "react-router-dom";
import {
  TextField,
  Button,
  Container,
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Table,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Grid,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSwipeable } from "react-swipeable";
import SwipeableViews from "react-swipeable-views";
import useFirebaseData from "../../Hooks/getFireStoreObject";

const StarRating = ({ rating, maxRating, onRate }) => {
  const handleRate = (newRating) => {
    if (onRate) {
      onRate(newRating);
    }
  };

  return (
    <div>
      {[...Array(maxRating)].map((_, index) => {
        const starRating = index + 1;
        return (
          <IconButton
            key={index}
            onClick={() => handleRate(starRating)}
            size="small"
            style={{
              color: starRating <= rating ? "gold" : "gray", // Change the color of filled stars to blue and empty stars to gray
            }}
          >
            {starRating <= rating ? <StarIcon /> : <StarBorderIcon />}
          </IconButton>
        );
      })}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(0),
    backgroundColor: "white",
  },
  containerOut: {
    padding: theme.spacing(4),
    backgroundImage:
      "url(https://images.pexels.com/photos/3745234/pexels-photo-3745234.jpeg?auto=compress&cs=tinysrgb&w=600&lazy=load)",
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    marginTop: theme.spacing(0), // Increase the negative margin value
  },
}));
const theme = createTheme({
  typography: {
    fontFamily: "Times New Roman", // Specify the font family you want to use
    padding: 0,
  },
});
function Resteraunt() {
  const classes = useStyles();
  const [place, setPlace] = useState(null);
  const [places, setPlaces] = useState([]);
  const { uid, placeid } = useParams();  const [loading, setLoading] = useState(true);

  //async get the firebaseobject make sure it is loaded
 
  const SwipeableTableRow = ({ children, onSwipeLeft }) => {
    const [swipeIndex, setSwipeIndex] = useState(0);

    const handleChangeIndex = (index) => {
      setSwipeIndex(index);
      if (index === 1) {
        onSwipeLeft();
      }
    };

    return (
      <TableRow>
        <TableCell style={{ padding: 0 }}>
          <div style={{ width: "100%", height: "100%" }}>
            <div>
              <div>
                <SwipeableViews
                  index={swipeIndex}
                  onChangeIndex={handleChangeIndex}
                  style={{ width: "100%" }}
                >
                  <div>{children}</div>
                  <div
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      height: "100%",
                      fontSize: "1.5rem",
                    }}
                  >
                    Delete
                  </div>
                </SwipeableViews>
              </div>
            </div>
          </div>
        </TableCell>
      </TableRow>
    );
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, `users/${uid}/places/${placeid}/info`),
      (snapshot) => {
        const fetchedPlaces = [];
        snapshot.docs.forEach((doc) => {
          const placeData = doc.data();
          placeData.id = doc.id; // add the id of the doc to the data object
          fetchedPlaces.push(placeData);
        });
        setPlaces(fetchedPlaces);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [uid, placeid]);

  const handlePlaceSubmit = async (event) => {
    event.preventDefault();
    await savePlaceToFirestore();
    setPlace(null);
  };

  const savePlaceToFirestore = async () => {
    const infoRef = doc(db, `users/${uid}/places/${placeid}/info/${uuidv4()}`);

    const placeData = {
      name: place.name,
      address: place.formatted_address,
      place_id: place.place_id,
    };

    try {
      await setDoc(infoRef, placeData);
    } catch (error) {
      console.error("Error saving place to Firestore: ", error);
    }
  };

  const handleRatingChange = async (placeId, newRating) => {
    const infoRef = doc(db, `users/${uid}/places/${placeid}/info/${placeId}`);
    try {
      await updateDoc(infoRef, { rating: newRating });
    } catch (error) {
      console.error("Error updating rating in Firestore: ", error);
    }
  };
  const handleDeletePlace = async (placeId) => {
    const infoRef = doc(db, `users/${uid}/places/${placeid}/info/${placeId}`);

    try {
      await deleteDoc(infoRef);
    } catch (error) {
      console.error("Error deleting place from Firestore: ", error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container  className={classes.containerOut}>
        <Container
          maxWidth="sm"
          sx={{
            bgcolor: "white",
            fontFamily: "Inter",
            borderRadius: 10,
            padding: 1,
            opacity: 0.95,
          }}
          className={classes.container}
        >
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="h4"
              fontFamily={"Roboto"}
              sx={{ textAlign: "center" }}
              paddingTop="5%"
              
            >

            Favorite Places

            </Typography>

            <Box sx={{ mt: 1 }}>
              <Table>
                <TableBody>
                  {places.map((place) => (
                    <SwipeableTableRow
                      key={place.id}
                      onSwipeLeft={() => handleDeletePlace(place.id)}
                    >
                      <>
                        <TableCell>
                          <DeleteIcon
                            onClick={() => handleDeletePlace(place.id)}
                          />
                        </TableCell>
                        <TableCell
                          fontFamily={"Inter"}
                          sx={{ fontSize: "1.2rem" }}
                        >
                          {place.name}
                        </TableCell>
                        <TableCell>
                          {/* Add the StarRating component */}
                          <StarRating
                            rating={place.rating || 0}
                            maxRating={5}
                            onRate={(newRating) =>
                              handleRatingChange(place.id, newRating)
                            }
                          />
                        </TableCell>
                      </>
                    </SwipeableTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Box>
          <div style={{ marginBottom: "0px" }}></div>

          <form onSubmit={handlePlaceSubmit} noValidate autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12} textAlign="center">
                <TextField
                  label="Search for a place"
                  value={place ? place.name : ""}
                  onChange={(event) =>
                    setPlace({ ...place, name: event.target.value })
                  }
                  InputProps={{
                    inputProps: {
                      ref: (input) => {
                        if (input != null) {
                          const autocomplete =
                            new window.google.maps.places.Autocomplete(input);
                          autocomplete.addListener("place_changed", () => {
                            const selectedPlace = autocomplete.getPlace();
                            setPlace(selectedPlace);
                          });
                        }
                      },
                    },
                  }}
                  sx={{
                    width: "50%",
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                style={{ paddingBottom: "16px" }}
              >
                <Button variant="contained" color="primary" type="submit">
                  Save Spot
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Container>
    </ThemeProvider>
  );
}

export default Resteraunt;
