import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { CircleLoader } from "react-spinners";
import { Link, useParams } from "react-router-dom";

import ParentComponent from "../../Components/ImageManager";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Questions from "../../Components/QuestionsPage";
import Publish from "../../Components/Publish";

import { auth } from "../../firebase";
import useDebounce from "../../Hooks/useDebounce";
import { firebaseConfig } from "../../firebase";
import { FirebaseAppProvider } from "reactfire";
import AllImages from "../../Components/AllImages";
import PositionedMenu from "../../Components/PositionedMenu";
import { makeStyles } from "@mui/styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFirebaseData from "../../Hooks/getFireStoreObject";
import { useEffect } from "react";
import { ClimbingBoxLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    justifyContent: "center",
    minHeight: "280px",
  },
  media: {
    height: "180px",
    objectFit: "cover",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingBottom: theme.spacing(0.5), // reduce the bottom padding
  },

  // Add a new class for the positioned menu
  positionedMenu: {
    position: "fixed !important",
    top: 12,
    left: 12,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "fixed !important",

      top: "calc(100% - 60px)", // Move the menu to the bottom on mobile
    },
  },

  card: {
    backgroundColor: "#f5f5f5",
    margin: theme.spacing(0),
    width: "40%",
    minWidth: 300,
    maxHeight: "100px !important",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      minHeight: "30px",
    },
  },
  pageContainer: {
    backgroundImage:
      "url('https://4kwallpapers.com/images/walls/thumbs_3t/4587.png')",
    minHeight: "100vh",
    padding: theme.spacing(3),
    backgroundSize: "cover", // Cover the entire container while maintaining aspect ratio
    backgroundPosition: "center", // Center the background image
    backgroundRepeat: "no-repeat", // Prevent the background image from repeating
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  description: {
    flex: 1,
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

function QQuestions() {
  const { placeid } = useParams();
  const classes = useStyles();
  const [storedPlaceData, setStoredPlaceData] = useState(null);

  const [typedAnswers, setTypedAnswers] = useState({});

  const [questions, setQuestions] = useState([]);
  const [fetchedAnswers, setFetchedAnswers] = useState({});

  const placeData = useFirebaseData(false, placeid);

  const allQuestionsHaveAnswers = () => {
    return questions.every(
      (question) => question.answer || fetchedAnswers[question.id]
    );
  };

  // Use separate useEffect to handle data update
  useEffect(() => {
    if (placeData && !storedPlaceData) {
      setStoredPlaceData(placeData);
    }
  }, [placeData]);

  const place = storedPlaceData
    ? {
        ...storedPlaceData,
        info: storedPlaceData.info[0],
        questions: storedPlaceData.questions[1],
      }
    : null;
  const loading = !storedPlaceData;

  const [imageLoading, setImageLoading] = useState(false); // New state to track image loading

  const [backgroundImage, setBackgroundImage] = useState("");
  const defaultImage =
    "https://media.cntraveller.com/photos/611bece34e09f53b43732f58/16:9/w_3200,h_1800,c_limit/beautiful.jpg";
  const debouncedPlaceName = useDebounce(place ? place.name : "", 5);

  const fetchBackgroundImage = async () => {
    setImageLoading(true);
    const imageUrlFromFirestore = placeData ? placeData.imageUrl : null;
    if (imageUrlFromFirestore) {
      setBackgroundImage(imageUrlFromFirestore);
      setImageLoading(false);
      return;
    }
    const apiKey = "Nz7zubGYwIk1O---wFis9hHycUu8fEyRnmHYOXtMalk";
    const url = `https://api.unsplash.com/photos/random?query=${place.name}&orientation=landscape&w=3840&h=2160`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Client-ID ${apiKey}`,
        },
      });
      console.log("Unsplash API response status:", response.status);

      const data = await response.json();
      if (data.urls && data.urls.regular != null) {
        setBackgroundImage(data.urls.regular);
        setImageLoading(false); // Set image loading state to false after fetching
      } else {
        setBackgroundImage(defaultImage);
        setImageLoading(false); // Set image loading state to false after fetching
      }
    } catch (error) {
      console.error(error);
      setBackgroundImage(defaultImage);
    }
    setImageLoading(false); // Set image loading state to false after fetching
  };
  useEffect(() => {
    if (place) {
      fetchBackgroundImage();
    }
  }, [placeid, debouncedPlaceName]);

  const handleDeleteAnswer = async (questionId) => {
    // Remove the answer from both fetchedAnswers and typedAnswers
    const updatedFetchedAnswers = { ...fetchedAnswers };
    delete updatedFetchedAnswers[questionId];
    const updatedTypedAnswers = { ...typedAnswers };
    delete updatedTypedAnswers[questionId];

    // Update Firestore
    const db = getFirestore();
    const answersRef = doc(
      db,
      "users",
      getUid(),
      "places",
      placeid,
      "questions",
      "answers"
    );

    await updateDoc(answersRef, {
      [questionId]: "",
    });

    // Update local state
    setFetchedAnswers(updatedFetchedAnswers);
    setTypedAnswers(updatedTypedAnswers);
  };

  const handleSubmitAllAnswers = async () => {
    const updatedAnswers = { ...fetchedAnswers, ...typedAnswers };

    const db = getFirestore();
    const answersRef = doc(
      db,
      "users",
      getUid(),
      "places",
      placeid,
      "questions",
      "answers"
    );

    await setDoc(answersRef, updatedAnswers, { merge: true });

    setFetchedAnswers(updatedAnswers);
    setTypedAnswers({}); // Clear typed answers after submitting
    toast.success("All answers submitted!");
  };

  useEffect(() => {
    const fetchAnswers = async () => {
      const db = getFirestore();
      const answersRef = doc(
        db,
        "users",
        getUid(),
        "places",
        placeid,
        "questions",
        "answers"
      );
      const answersSnap = await getDoc(answersRef);

      if (answersSnap.exists()) {
        const answersData = answersSnap.data();
        setFetchedAnswers(answersData);
      }
    };
    fetchAnswers();
  }, [placeid]);

  useEffect(() => {
    const fetchQuestions = async () => {
      const db = getFirestore();

      const questionsDocRef = doc(
        db,
        "users",
        getUid(),
        "places",
        placeid,
        "questions",
        "questions"
      );

      const questionsSnapshot = await getDoc(questionsDocRef);

      if (questionsSnapshot.exists()) {
        const questionsData = Object.entries(questionsSnapshot.data()).map(
          ([id, question]) => ({
            id,
            ...question,
          })
        );
        questionsData.sort((a, b) => a.id.localeCompare(b.id));

        // Add the static question
        questionsData.push({
          id: "static-question",
          text: "Where are you traveling from?",
        });

        setQuestions(questionsData);
      } else {
        console.log("No such document!");
      }
    };

    if (placeid) {
      fetchQuestions();
    }
  }, [placeid]);

  const getUid = () => {
    const user = auth.currentUser;
    if (user) {
      return user.uid;
    } else {
      console.log("User not authenticated");
      return null;
    }
  };
  const [imageUrls, setImageUrls] = useState([]);

  const handleImagesFetched = (urls) => {
    setImageUrls(urls);
  };

  if (loading || !place || imageLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",

        }}
      >
       <ClimbingBoxLoader></ClimbingBoxLoader>
      </div>
    );
  }

  return (
    <div>
      <ToastContainer />

      <TableContainer sx={{ margin: "auto", mt: 2, maxHeight: "40%" }}>
        <Table cellSpacing={2}>
          <TableHead sx={{ backgroundColor: "" }}>
            <TableRow>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderBottom: "none",
                  padding: "2px",
                }}
              >
                Question
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: "bold",
                  borderBottom: "none",
                  padding: "2px",
                }}
              >
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.map((question) => (
              <TableRow key={question.id}>
                <TableCell sx={{ borderBottom: "none", padding: "2px" }}>
                  {question.text}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", padding: "2px" }}>
                  {question.answer || fetchedAnswers[question.id] || (
                    <TextField
                      name={question.id}
                      margin="none"
                      size="large"
                      //no outline
                      //longer make them longer
                      fullWidth={true}


                      variant="standard"
                      value={
                        typedAnswers[question.id] ||
                        fetchedAnswers[question.id] ||
                        ""
                      }
                      onChange={(e) =>
                        setTypedAnswers({
                          ...typedAnswers,
                          [question.id]: e.target.value,
                        })
                      }
                      sx={{ width: 50 }}
                    />
                  )}
                </TableCell>
                <TableCell sx={{ borderBottom: "none", padding: "0px" }}>
                  <IconButton
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 0 }}
                    onClick={() => handleDeleteAnswer(question.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        onClick={handleSubmitAllAnswers}
        variant="contained"
        color="primary"
        sx={{ mt: 3, display: "block", margin: " auto", marginBottom: "10px" }} // Center the button by adding these styles
        //disable the button when all questions are answered
        disabled={allQuestionsHaveAnswers()}
      >
        Submit Answers
      </Button>
    </div>
  );
}

export default QQuestions;
