import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./pages.css";
import Resteraunts from "../../Components/Resteraunts";
import BottomNav from "../../Components/Navbar/Navbar";

import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { CircleLoader } from "react-spinners";
import Grid from "@mui/material/Grid";

import ParentComponent from "../../Components/ImageManager";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Questions from "../../Components/QuestionsPage";
import Publish from "../../Components/Publish";

import { auth } from "../../firebase";
import useDebounce from "../../Hooks/useDebounce";
import { firebaseConfig } from "../../firebase";
import { FirebaseAppProvider } from "reactfire";
import AllImages from "../../Components/AllImages";
import { MageList } from "../../Components/ImageManager";
import PositionedMenu from "../../Components/PositionedMenu";
import { makeStyles } from "@mui/styles";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFirebaseData from "../../Hooks/getFireStoreObject";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    justifyContent: "center",
    minHeight: "280px",
  },
  media: {
    height: "180px",
    objectFit: "cover",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingBottom: theme.spacing(0.5), // reduce the bottom padding
  },

  // Add a new class for the positioned menu
  positionedMenu: {
    position: "fixed !important",
    top: 12,
    left: 12,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "fixed !important",

      top: "calc(100% - 60px)", // Move the menu to the bottom on mobile
    },
  },

  card: {
    backgroundColor: "#000000",
    margin: theme.spacing(0),
    width: "40%",
    minWidth: 300,
    maxHeight: "100px !important",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      minHeight: "30px",
    },
  },
  pageContainer: {
    backgroundImage:
      "url('https://4kwallpapers.com/images/walls/thumbs_3t/4587.png')",
    minHeight: "100vh",
    padding: theme.spacing(3),
    backgroundSize: "cover", // Cover the entire container while maintaining aspect ratio
    backgroundPosition: "center", // Center the background image
    backgroundRepeat: "no-repeat", // Prevent the background image from repeating
  },

  link: {
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  description: {
    flex: 1,
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

const PlacePagePrivate = ({ user }) => {
  const { placeid } = useParams();

  const [questionsLoaded, setQuestionsLoaded] = useState(false); // Added questionsLoaded state

  const classes = useStyles();
  const [storedPlaceData, setStoredPlaceData] = useState(null);
  const placeData = useFirebaseData(false, placeid);
  // Use separate useEffect to handle data update
  useEffect(() => {
    if (placeData && !storedPlaceData) {
      setStoredPlaceData(placeData);
    }
  }, [placeData]);

  const place = storedPlaceData
    ? {
        ...storedPlaceData,
        info: storedPlaceData.info[0],
        questions: storedPlaceData.questions[1],
      }
    : null;
  const loading = !storedPlaceData;

  const [imageLoading, setImageLoading] = useState(false); // New state to track image loading

  const [backgroundImage, setBackgroundImage] = useState("");
  const defaultImage =
    "https://media.cntraveller.com/photos/611bece34e09f53b43732f58/16:9/w_3200,h_1800,c_limit/beautiful.jpg";
  const debouncedPlaceName = useDebounce(place ? place.name : "", 5);

  const fetchBackgroundImage = async () => {
    setImageLoading(true);
    const imageUrlFromFirestore = placeData ? placeData.imageUrl : null;
    if (imageUrlFromFirestore) {
      setBackgroundImage(imageUrlFromFirestore);
      setImageLoading(false);
      return;
    }
    const apiKey = "Nz7zubGYwIk1O---wFis9hHycUu8fEyRnmHYOXtMalk";
    const url = `https://api.unsplash.com/photos/random?query=${place.name}&orientation=landscape&w=3840&h=2160`;

    try {
      const response = await fetch(url, {
        headers: {
          Authorization: `Client-ID ${apiKey}`,
        },
      });
      console.log("Unsplash API response status:", response.status);

      const data = await response.json();
      if (data.urls && data.urls.regular != null) {
        setBackgroundImage(data.urls.regular);
        setImageLoading(false); // Set image loading state to false after fetching
      } else {
        setBackgroundImage(defaultImage);
        setImageLoading(false); // Set image loading state to false after fetching
      }
    } catch (error) {
      console.error(error);
      setBackgroundImage(defaultImage);
    }
    setImageLoading(false); // Set image loading state to false after fetching
  };
  useEffect(() => {
    if (place) {
      fetchBackgroundImage();
    }
  }, [placeid, debouncedPlaceName]);

  const [imageUrls, setImageUrls] = useState([]);

  const handleImagesFetched = (urls) => {
    setImageUrls(urls);
  };

  if (loading || !place || imageLoading) {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <CircleLoader
          type="ThreeDots" // Type of spinner
          color="#00BFFF" // Color of spinner
          height={80} // Height of spinner
          width={80} // Width of spinner
        />
      </div>
    );
  }

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <div
        style={{
          backgroundSize: "cover",
          minHeight: "10vh",
          fontFamily: "Inter",
        }}
      >
        <div className={`${classes.positionedMenu} hide-on-small`}></div>
        <Box
          //sx to make it at the top
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginleft: "10",
            alignContent: "center",
            padding: "0",
          }}
        >
          <h1
            style={{
              marginTop: "0",
              fontFamily: "roberto",
              marginBottom: "0.5rem",
            }}
          >
            {place.name}
          </h1>
          <p
            style={{
              marginTop: "0",
              marginBottom: "-1rem",
              fontFamily: "roberto",
            }}
          >
            {place.description}
          </p>
        </Box>
      </div>

      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "Inter",
          backgroundColor: "FFFFFF",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <div
              style={{
                marginLeft: "40px",
              }}
            >
              <Questions />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              backgroundImage: `url(https://images.pexels.com/photos/7135037/pexels-photo-7135037.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            md={6}
            style={{
              backgroundImage: `url(https://images.pexels.com/photos/7130557/pexels-photo-7130557.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />{" "}
          <Grid item xs={12} md={6} style={{ paddingLeft: 0, paddingTop: 0 }}>
            <Resteraunts />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" align="center" fontFamily="Inter">
              Upload Photos from {place.name}
            </Typography>{" "}
            <ParentComponent onImagesFetched={handleImagesFetched} />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              backgroundImage: `url(https://images.pexels.com/photos/7130498/pexels-photo-7130498.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid
            item
            xs={12}
            md={6}
            style={{
              backgroundImage: `url(https://images.pexels.com/photos/7134986/pexels-photo-7134986.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
          <Grid item xs={12} md={6}>
            <Typography variant="h3" align="center" fontFamily="Inter">
              Photos from {place.name}
            </Typography>
            <MageList />
          </Grid>
        </Grid>
        <BottomNav />
      </div>
    </FirebaseAppProvider>
  );
};

export default PlacePagePrivate;
