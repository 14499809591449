import { Typography } from "@mui/material";
import BottomNav from "../../Components/Navbar/Navbar";


function Favorites() {
  return (
    <div>
      <Typography variant="h3">Favorites</Typography>
      <Typography variant="body1">
   under construction.
      </Typography>
      <BottomNav/>

    </div>
  );
}

export default Favorites;
