import { useState, useEffect, useMemo } from "react";
import { auth } from "./firebase";
import { onSnapshot, collection, addDoc } from "firebase/firestore";
import "./index.css";
import SignIn from "./Pages/Login";
import AddPlaceForm from "./Pages/AddPlace";
import PlacePage from "./Pages/PlacePage";
import { db } from "./firebase";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import React from "react";
import { firebaseConfig } from "./firebase";
import { FirebaseAppProvider, FirestoreProvider } from "reactfire";
import Profile from "./Pages/Profile";
import BottomNav from "./Components/Navbar/Navbar";
import Login from "./Pages/Login";
import Favorites from "./Pages/Favorites";
import { Box } from "@mui/material";
import PlaceListNew from "./Pages/Cards";
import PublicPlacePage from "./Pages/PublicPlacePage";
import { CircularProgress } from "@mui/material";

const ProtectedRoute = ({ user, loading, children }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!loading && !user) {
      navigate("/login");
    }
  }, [user, loading, navigate]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return user ? children : null;
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#007bff",
    },
    secondary: {
      main: "#6c757d",
    },
  },
});

function App() {
  const [loading, setLoading] = useState(true);

  const [user, setUser] = useState(null);
  const [places, setPlaces] = useState([]);
  const RedirectToHome = () => {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/home");
    }, [navigate]);
    return null;
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      setLoading(false);
      localStorage.setItem("user", JSON.stringify(user));
    });

    const storedUser = JSON.parse(localStorage.getItem("user"));
    if (storedUser) {
      setUser(storedUser);
    }

    return unsubscribe;
  }, []);

  useEffect(() => {
    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          func.apply(null, args);
        }, delay);
      };
    };

    const getPlaces = async () => {
      const placesRef = collection(db, "places");
      const placesSnapshot = await onSnapshot(placesRef, (snapshot) => {
        const placesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPlaces(placesData);
      });
    };

    const debouncedGetPlaces = debounce(getPlaces, 500); // debounced version of getPlaces

    debouncedGetPlaces();

    return () => {
      clearTimeout(debouncedGetPlaces);
    };
  }, []);

  const addPlace = async (place) => {
    try {
      const docRef = await addDoc(collection(db, "places"), {
        ...place,
        authorId: user.uid,
      });
      console.log("Document written with ID:", docRef.id);
    } catch (e) {
      console.error("Error adding document:", e);
    }
  };
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <ThemeProvider theme={theme}>
        <Box sx={{ position: "relative", minHeight: "100vh" }}>
          <BrowserRouter>
            <Box sx={{ paddingBottom: "60px" }}>
              <Routes>
                <Route
                  path="/login"
                  element={user ? <RedirectToHome /> : <Login />}
                />
                <Route
                  path="/home"
                  element={
                    <ProtectedRoute user={user} loading={loading}>
                      <PlaceListNew isPublic={false} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/addplace"
                  element={
                    <ProtectedRoute user={user} loading={loading}>
                      <AddPlaceForm addPlace={addPlace} />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/users/:uid/places/:placeid"
                  element={
                    <ProtectedRoute user={user} loading={loading}>
                      <PlacePage user={user}  />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/public/places/:placeid"
                  element={
                    <ProtectedRoute user={user} loading={loading}>
                      <PublicPlacePage user={user} />
                    </ProtectedRoute>
                  }
                />

                <Route path="/favorites" element={<Favorites />} />
                <Route
                  path="/search"
                  element={<PlaceListNew isPublic={true} />}
                />
                <Route
                  path="/profile"
                  element={
                    <ProtectedRoute user={user} loading={loading}>
                      <Profile />
                    </ProtectedRoute>
                  }
                />

                <Route path="*" element={<RedirectToHome />} />
              </Routes>
            </Box>
          </BrowserRouter>
        </Box>
      </ThemeProvider>
    </FirebaseAppProvider>
  );
}
export default App;
