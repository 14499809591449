import { useState } from "react";
import { auth, db } from "../../firebase";
import {
  getDoc,
  setDoc,
  updateDoc,
  doc,
  getDocs,
  collection,
} from "firebase/firestore";
import { IconButton } from "@mui/material";
import { Public as PublicIcon } from "@mui/icons-material";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { deleteObject } from "firebase/storage";
import { deleteDoc } from "firebase/firestore";

function PublishButton(props) {
  const [published, setPublished] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const handlePublish = async () => {
    setIsPublishing(true); // Start publishing

    const user = auth.currentUser;
    const firstName = user.displayName.split(" ")[0];
    const lastInitial = user.displayName
      .slice(user.displayName.indexOf(" ") + 1, user.displayName.length)
      .charAt(0);

    // Add the user's name as a field to the document
    const placeDocRef = doc(db, "users", user.uid, "places", props.placeId);
    const placeDoc = await getDoc(placeDocRef);
    const data = placeDoc.data();

    // Copy subcollections
    await copySubcollection(
      placeDocRef,
      `public/${props.placeId}`,
      "questions"
    );
    await copySubcollection(placeDocRef, `public/${props.placeId}`, "answers");
    await copySubcollection(placeDocRef, `public/${props.placeId}`, "info");

    // Delete all images from the public storage for the place
    await deleteAllPublicImages(props.placeId);

    // Copy images from private to public storage
    const publicImageInfo = await copyImagesToPublicStorage(
      user.uid,
      props.placeId
    );

    await setDoc(doc(db, "public", props.placeId), {
      ...data,
      firstName,
      lastInitial,
      uid: user.uid, // add the uid field here
    });

    // Delete the existing public photos subcollection
    // Delete the existing public photos subcollection
    const publicPhotosRef = collection(
      doc(db, "public", props.placeId),
      "photos"
    );
    const publicPhotosSnapshot = await getDocs(publicPhotosRef);
    const deletePhotoPromises = publicPhotosSnapshot.docs.map((docSnapshot) => {
      return deleteDoc(docSnapshot.ref);
    });
    await Promise.all(deletePhotoPromises);

    // Store the image URLs in the "photos" subcollection
    const addPhotoPromises = publicImageInfo.map((imageInfo, index) => {
      return setDoc(doc(publicPhotosRef, `photo${index}`), {
        url: imageInfo.url,
      });
    });

    await Promise.all(addPhotoPromises);

    // Update the published status of the document
    await updateDoc(placeDocRef, { published: true });
    setPublished(true);
    setIsPublishing(false); // Finish publishing

    toast.success("Place published!"); // Add this line
  };

  const deleteAllPublicImages = async (placeId) => {
    const storage = getStorage();
    const publicImagesPath = `public/${placeId}/photos`;
    const publicImagesRef = ref(storage, publicImagesPath);

    const publicImagesList = await listAll(publicImagesRef);
    const deleteImagePromises = publicImagesList.items.map(async (item) => {
      await deleteObject(item);
    });

    await Promise.all(deleteImagePromises);
  };
  const copyImagesToPublicStorage = async (userId, placeId) => {
    const storage = getStorage();
    const privateImagesPath = `users/${userId}/places/${placeId}/photos`;
    const publicImagesPath = `public/${placeId}/photos`;

    // Delete existing public images before copying new ones
    await deleteAllPublicImages(placeId);

    const privateImagesRef = ref(storage, privateImagesPath);
    const publicImageInfo = []; // Array to store public image info

    const privateImagesList = await listAll(privateImagesRef);
    const copyImagePromises = privateImagesList.items.map(async (item) => {
      const privateImagePath = `${privateImagesPath}/${item.name}`;
      const publicImagePath = `${publicImagesPath}/${item.name}`;

      const url = await getDownloadURL(ref(storage, privateImagePath));
      const response = await fetch(url);
      const blob = await response.blob();

      await uploadBytes(ref(storage, publicImagePath), blob);
      const publicUrl = await getDownloadURL(ref(storage, publicImagePath));
      publicImageInfo.push({ url: publicUrl, name: item.name }); // Add the public URL and name to the array
    });

    await Promise.all(copyImagePromises);
    return publicImageInfo; // Return the array of public image info
  };

  const copySubcollection = async (
    sourceRef,
    destinationPath,
    subcollectionName
  ) => {
    const sourceSubcollectionRef = collection(sourceRef, subcollectionName);
    const snapshot = await getDocs(sourceSubcollectionRef);
    snapshot.docs.forEach(async (docSnapshot) => {
      const data = docSnapshot.data();
      await setDoc(
        doc(db, destinationPath, subcollectionName, docSnapshot.id),
        data
      );
    });
  };

  return (
    <>
      <IconButton
        color="primary"
        aria-label="publish"
        onClick={handlePublish}
        disabled={isPublishing}
      >
        {isPublishing ? <CircularProgress size={24} /> : <PublicIcon />}
      </IconButton>
      <ToastContainer />
    </>
  );
}

export default PublishButton;
