import React from "react";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  ImageList,
  ImageListItem,
} from "@mui/material";
import useFirebaseData from "../../Hooks/getFireStoreObject";
import BottomNav from "../../Components/Navbar/Navbar";
import { ClimbingBoxLoader } from "react-spinners";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    minHeight: "100vh",
    padding: theme.spacing(3),
    backgroundColor: "#f5f5f5",
  },
  title: {
    fontSize: theme.typography.h4.fontSize,
    marginBottom: theme.spacing(2),
    color: "#3f51b5",
  },
  subtitle: {
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(1),
    color: "#3f51b5",
  },
  listItem: {
    marginBottom: theme.spacing(1),
  },
  table: {
    minWidth: "100%",
  },
}));

const PlacePage = () => {
  const { placeid } = useParams();
  const placeData = useFirebaseData(true, placeid);
  const classes = useStyles();

  if (!placeData) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "black",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <ClimbingBoxLoader size={29} color="#ffffff" />
      </div>
    );
  }

  const { name, description, info, questions, images, answers } = placeData;
  console.log("Questions object:", questions);

  console.log(placeData);

  const mergedQuestions = Object.entries(questions?.[1] || {}).map(
    ([key, questionObj]) => ({
      key,
      question: questionObj?.text || "No question available.",
      answer: questions?.[0]?.[key] || "No answer available.",
    })
  );
  


  

  return (
    <div className={classes.pageContainer}>
      <Typography className={classes.title} variant="h4">
        {name}
      </Typography>
      <Typography variant="body1">{description}</Typography>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="info table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Rating</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {info.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.name}</TableCell>
                <TableCell>{item.address}</TableCell>
                <TableCell>{item.rating}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {mergedQuestions.map(({ key, question, answer }, index) => (
        <li key={index} className={classes.listItem}>
          <Typography
            variant="body1"
            style={{
              display: "inline",
              fontWeight: "",
              marginRight: "5px",
            }}
          >
            Q: {" "}
          
            {question}
          </Typography>
          <br />
          <Typography variant="body2" style={{ marginLeft: "20px" }}>
            A: {answer}
          </Typography>
        </li>
      ))}

      <Typography className={classes.subtitle} variant="h5">
        Images
      </Typography>
      <ImageList className={classes.imageList} cols={3}>
        {images.map((image, index) => (
          <ImageListItem key={index}>
            <img
              src={image.url}
              alt={`Image ${index}`}
              loading="lazy"
              style={{ objectFit: "contain", width: "100%", height: "100%" }}
            />
          </ImageListItem>
        ))}
      </ImageList>

      <BottomNav />
    </div>
  );
};
export default PlacePage;
