import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import React from "react";
import { collection, onSnapshot } from "firebase/firestore";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import BottomNav from "../../Components/Navbar/Navbar";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useParams } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { getFirestore, getDoc, doc, deleteDoc } from "firebase/firestore";
import "./pages.css";
import { useTheme } from "@emotion/react";
import { updateDoc } from "firebase/firestore";
import PublishIcon from "@mui/icons-material/Publish";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { signOut } from "firebase/auth"; // Import the signOut function
import { db } from "../../firebase"; // Make sure to import the Firestore instance
import RefreshIcon from "@mui/icons-material/Refresh";
import { getDocs } from "firebase/firestore";
import PublishButton from "../../Components/Publish";
import { setDoc } from "firebase/firestore";
import { auth } from "../../firebase";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(1),
    justifyContent: "center",
    minHeight: "280px",
  },
  media: {
    height: "180px",
    objectFit: "cover",
  },
  content: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingBottom: theme.spacing(0.5), // reduce the bottom padding
  },

  // Add a new class for the positioned menu
  positionedMenu: {
    position: "fixed !important",
    top: 12,
    left: 12,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "fixed !important",

      top: "calc(100% - 60px)", // Move the menu to the bottom on mobile
    },
  },

  card: {
    backgroundColor: "#f5f5f5",
    margin: theme.spacing(0),
    width: "80%",
    minWidth: 300,
    [theme.breakpoints.up("sm")]: {
      width: "40%",
    },
  },
  pageContainer: (props) => ({
    backgroundImage: `url('${
      props.isPublic
        ? "https://images.pexels.com/photos/3745234/pexels-photo-3745234.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
        : "https://images.pexels.com/photos/3648850/pexels-photo-3648850.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
    }')`,
    minHeight: "calc(100vh - 110px)",
    padding: theme.spacing(3),
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed", // Add this property to make the background image fixed
  }),

  link: {
    textDecoration: "none",
    color: "inherit",
  },
  title: {
    fontSize: theme.typography.h5.fontSize,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      fontSize: theme.typography.h6.fontSize,
    },
  },
  description: {
    flex: 1,
    fontSize: "0.9rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
    },
  },
}));

function PlaceList({ isPublic }) {
  // Add the isPublic prop
  const navigate = useNavigate();
  const theme2 = useTheme();
  const classes = useStyles({ isPublic });
  const [images, setImages] = useState({});
  const [titles, setTitles] = useState({});
  const [places, setPlaces] = useState([]);
  const [user, setUser] = useState(null);
  const [published, setPublished] = useState({});
  const [showDeletePopup, setShowDeletePopup] = useState(false); // Add state variable for delete popup

  const { uid } = useParams(); // Add this line to get the user ID from the URL params
  useEffect(() => {
    const getPublishedStatus = async () => {
      const publishedStatus = {};
      const userUid = uid || (user && user.uid);
      if (!userUid) {
        console.error("userUid is null or undefined", { uid, user });
        return;
      }
      const placesRef = collection(db, "users", userUid, "places");
      const querySnapshot = await getDocs(placesRef);
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData && docData.published) {
          publishedStatus[doc.id] = docData.published;
        }
      });

      setPublished(publishedStatus);
    };
    getPublishedStatus();
  }, [uid, user]);

  const handleLogout = () => {
    signOut(getAuth())
      .then(() => {
        // Sign-out successful.
        console.log("User signed out.");
        navigate("/login"); // Navigate to the login page or any other page you want
      })
      .catch((error) => {
        // An error happened.
        console.error("Error signing out:", error.message);
      });
  };
  useEffect(() => {
    // Listen for changes in the user's authentication state
    const unsubscribeAuth = onAuthStateChanged(getAuth(), (currentUser) => {
      setUser(currentUser);
    });

    // Clean up the auth listener when the component is unmounted
    return () => {
      unsubscribeAuth();
    };
  }, []);

  const handlePublish = async (placeId) => {
    const userUid = uid || user.uid;
    const placeDocRef = doc(db, "users", userUid, "places", placeId);
    await updateDoc(placeDocRef, { published: true });

    // Copy the place data to the public collection
    const publicRef = collection(db, "public");
    const publicDocRef = doc(publicRef, placeId);
    const placeDoc = await getDoc(placeDocRef);
    await setDoc(publicDocRef, placeDoc.data());

    // Set security rules to allow read access for all authenticated users
    await setDoc(
      publicDocRef,
      {
        public: true,
      },
      { merge: true }
    );
  };

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        const userUid = uid || user.uid;
        let placesRef;
        if (isPublic) {
          // Fetch public places
          placesRef = collection(db, "public");
          // Apply additional query to filter by userUid if needed
        } else {
          // Fetch private places
          const userDocRef = doc(db, "users", userUid);
          placesRef = collection(userDocRef, "places");
        }
        const unsubscribeSnapshot = onSnapshot(placesRef, (snapshot) => {
          const placesData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPlaces(placesData);
        });
        // Clean up the snapshot listener when the component is unmounted
        return () => {
          unsubscribeSnapshot();
        };
      };

      fetchData();
    }
  }, [user, uid, isPublic]); // Add isPublic as a dependency

  const handleDelete = async (placeId, isPublic) => {
    // Display the delete popup
    setShowDeletePopup(true);
    // Save the placeId and isPublic variables to local state
    // so that they can be accessed in the confirmDelete function
    setPlaceToDelete({ placeId, isPublic });
  };
  const confirmDelete = async () => {
    const { placeId, isPublic } = placeToDelete;
    setShowDeletePopup(false);

    try {
      // Determine the collection from which to delete the place
      const collectionName = isPublic ? "public" : "places";
      const userUid = auth.currentUser.uid;
      const placeDocRef = isPublic
        ? doc(db, collectionName, placeId) // Correct reference for public places
        : doc(db, "users", userUid, collectionName, placeId); // Reference for private places

      // Delete the place from the specified collection
      await deleteDoc(placeDocRef);

      // Optionally, update the published status of the document in the user's private collection (if deleting from public)
      if (isPublic) {
        const userPlaceDocRef = doc(db, "users", userUid, "places", placeId);
        await updateDoc(userPlaceDocRef, { published: false });
      }

      // Update the places state to remove the deleted place
      setPlaces((prevPlaces) =>
        prevPlaces.filter((place) => place.id !== placeId)
      );
    } catch (error) {
      console.error("Error deleting place:", error);
    }
  };

  const [placeToDelete, setPlaceToDelete] = useState({});

  const handleClose = () => setShowDeletePopup(false);

  const renderDeletePopup = () => (
    <Dialog open={showDeletePopup} onClose={handleClose}>
      <DialogTitle>Are you sure you want to delete this place?</DialogTitle>
      <DialogContent>
        <DialogContentText>This action cannot be undone.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={confirmDelete}>Delete</Button>
      </DialogActions>
    </Dialog>
  );

  const handleDelete2 = async (placeId, isPublic) => {
    try {
      // Determine the collection from which to delete the place
      const collectionName = isPublic ? "public" : "places";
      const userUid = auth.currentUser.uid;
      const placeDocRef = isPublic
        ? doc(db, collectionName, placeId) // Correct reference for public places
        : doc(db, "users", userUid, collectionName, placeId); // Reference for private places

      // Delete the place from the specified collection
      await deleteDoc(placeDocRef);

      // Optionally, update the published status of the document in the user's private collection (if deleting from public)
      if (isPublic) {
        const userPlaceDocRef = doc(db, "users", userUid, "places", placeId);
        await updateDoc(userPlaceDocRef, { published: false });
      }

      // Update the places state to remove the deleted place
      setPlaces((prevPlaces) =>
        prevPlaces.filter((place) => place.id !== placeId)
      );
    } catch (error) {
      console.error("Error deleting place:", error);
    }
  };

  const fetchBackgroundImage = async (placeId, placeName, existingImageUrl) => {
    if (existingImageUrl) {
      return existingImageUrl;
    }
    try {
      const apiKey = "Nz7zubGYwIk1O---wFis9hHycUu8fEyRnmHYOXtMalk"; // fix later add N to the start
      const url = `https://api.unsplash.com/photos/random?query=${placeName}&orientation=landscape&width=4000&height=3000`;
      console.log(url);
      const response = await fetch(url, {
        headers: {
          Authorization: `Client-ID ${apiKey}`,
        },
      });
      const data = await response.json();
      if (data.urls && data.urls.regular) {
        const imageUrl = data.urls.regular;
        const userUid = uid || user.uid;
        const placeDocRef = doc(db, "users", userUid, "places", placeId);
        await updateDoc(placeDocRef, { imageUrl });
        return imageUrl;
      }
      return null;
    } catch (error) {
      console.error("Error fetching Unsplash API:", error);
      return null;
    }
  };

  const handleImageUpload = async (e, placeId) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    const file = e.target.files[0];
    const storage = getStorage();
    const storageRef = ref(
      storage,
      `images/${user.uid}/${placeId}/${file.name}`
    );
    await uploadBytes(storageRef, file);

    const imageUrl = await getDownloadURL(storageRef);
    const placeDocRef = doc(db, "users", user.uid, "places", placeId);
    await updateDoc(placeDocRef, { imageUrl });
  };

  const handleImageRefresh = async (placeId, placeName) => {
    const imageUrl = await fetchBackgroundImage(placeId, placeName, null);
    if (imageUrl) {
      setImages((prevImages) => ({
        ...prevImages,
        [placeId]: imageUrl,
      }));
      const userUid = uid || user.uid;
      const placeDocRef = doc(db, "users", userUid, "places", placeId);
      await updateDoc(placeDocRef, { imageUrl });
    }
  };

  useEffect(() => {
    const getImagesAndTitles = async () => {
      const imagePromises = places
        .filter((item) => item !== undefined)
        .map((place) =>
          fetchBackgroundImage(place.id, place.name, place.imageUrl)
        );

      const imageUrls = await Promise.all(imagePromises);
      const imageMap = imageUrls.reduce((acc, url, index) => {
        acc[places[index].id] = url;
        return acc;
      }, {});
      setImages(imageMap);

      // Get titles and descriptions
      const db = getFirestore();
      if (!places || places.length === 0) {
        return;
      }
      const titlePromises = places
        .filter((item) => item !== undefined && item.id !== undefined)
        .map(async (place) => {
          try {
            const docRef = doc(db, "users", place.userId, "places", place.id);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
              return [
                place.id,
                docSnap.data().name,
                docSnap.data().description,
              ];
            } else {
              return [place.id, "", ""];
            }
          } catch (error) {
            console.error(`Error while processing place: ${place.id}`, error);
            return [place.id, "", ""];
          }
        });

      const titleArray = await Promise.all(titlePromises);
      const titleMap = titleArray.reduce(
        (acc, [placeId, title, description]) => {
          acc[placeId] = { title, description };
          return acc;
        },
        {}
      );
      setTitles(titleMap);
    };

    getImagesAndTitles();
  }, [places]);
  return (
    <ThemeProvider theme={theme2}>
      {renderDeletePopup()}

      <Box
        className={classes.pageContainer}
        textAlign="center"
        sx={{
          display: "flex", // Add flex display
          flexDirection: "column", // Set flex direction to column
          alignItems: "center", // Center content horizontally
          position: "relative", // Add this line to position the PositionedMenu
        }}
      >
        <Typography
          variant="h2"
          fontFamily="Roboto"
          component="h3"
          color="white"
          gutterBottom
        >
          {isPublic ? "Public Places" : "My Places"}
        </Typography>

        <div className={classes.root}>
          {places.map((place) => (
            <React.Fragment key={place.id}>
              <Card key={place.id} className={classes.root}>
                <div className={classes.card}>
                  <Link
                    to={
                      isPublic
                        ? `/public/places/${place.id}`
                        : `/users/${uid || user.uid}/places/${place.id}`
                    }
                    className={classes.link}
                  >
                    <CardMedia
                      className={classes.media}
                      image={
                        place.imageUrl ||
                        images[place.id] ||
                        "https://images.pexels.com/photos/3694404/pexels-photo-3694404.jpeg?auto=compress&cs=tinysrgb&w=800"
                      }
                      title={titles[place.id]?.title || place.name}
                    />

                    <CardContent
                      className={classes.content}
                      sx={{ display: "flex", flexDirection: "column" }}
                    >
                      <Typography
                        variant="h5"
                        component="h2"
                        gutterBottom={false}
                        fontFamily="Times New Roman"
                        sx={{ marginBottom: "-30px" }} // Add the margin here
                      >
                        {titles[place.id]?.title || place.name}
                      </Typography>
                    </CardContent>
                  </Link>
                  <Typography
                    variant="p6"
                    color="textSecondary"
                    component="p"
                    sx={{ flex: "0 0 auto" }}
                    fontFamily="Times New Roman"
                  >
                    {titles[place.id]?.description || place.description}
                  </Typography>
                  <Grid container justifyContent="flex-end">
                    <Grid item>
                      {!isPublic && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(place.id, false)} // Pass false for private deletion
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}

                      {isPublic && place.uid === auth.currentUser.uid && (
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDelete(place.id, true)} // Pass true for public deletion
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Grid>
                    {/* Conditionally render the "Refresh Image" button */}
                    {!isPublic && (
                      <Grid item>
                        <IconButton
                          color="primary"
                          aria-label="refresh image"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleImageRefresh(place.id, place.name);
                          }}
                          sx={{ color: "gray" }} // Set the color to gray
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Grid>
                    )}
                    {/* Conditionally render the "Publish" button */}
                    {!isPublic && (
                      <Grid item>
                        <PublishButton
                          handlePublish={handlePublish}
                          placeId={place.id}
                        />
                      </Grid>
                    )}

                    {!isPublic && (
                      <Grid item>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            handleImageUpload(e, place.id);
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          style={{ display: "none" }}
                          id={`image-upload-${place.id}`}
                        />{" "}
                        <label htmlFor={`image-upload-${place.id}`}>
                          <IconButton
                            component="span"
                            color="secondary"
                            aria-label="upload image"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <PublishIcon />
                          </IconButton>
                        </label>
                      </Grid>
                    )}
                  </Grid>
                </div>{" "}
                {/* This closing tag was missing */}
              </Card>
            </React.Fragment>
          ))}
        </div>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column", // Set the flex direction to column
            alignItems: "center", // Center the buttons horizontally
            width: "50%", // Set the width of the container
            marginTop: "20px", // Add some margin to the top
          }}
        >
          {!isPublic && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "50%",
                marginTop: "20px",
              }}
            ></Box>
          )}
        </Box>
      </Box>
      <BottomNav />
    </ThemeProvider>
  );
}

export default PlaceList;
