import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  Typography,
  List,
  ListItem,
  ListItemText,
  Box,
  Button,
} from "@mui/material";
import BottomNav from "../../Components/Navbar/Navbar";
import { makeStyles } from "@mui/styles";
import { auth } from "../../firebase";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Avatar } from "@mui/material";

import { IconButton } from "@mui/material";
import { Drawer } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ListItemIcon } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PositionedMenu from "../../Components/PositionedMenu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Summary from "../../Components/Summary";
import { fontFamily } from "@mui/system";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginBottom: "1rem",
    color: theme.palette.primary.main,
    textAlign: "center",
    fontSize: "2rem",
    color: "black",
    font: "times new roman",
    margin: 0,
  },
  typography: {
    fontFamily: "Courier New",
  },
  fixedContainer: {
    position: "fixed",
    top: 10,
    left: 10,
    zIndex: 1,
  },
  button: {
    background: "linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 48,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(255, 105, 135, .3)",
    textTransform: "none",
    fontSize: 16,
    fontWeight: "bold",
  },

  subtitle: {
    marginBottom: "1rem",
    fontWeight: "bold",
    color: theme.palette.text.secondary,
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    borderRadius: "1rem",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  },
  listItem: {
    borderRadius: "1rem",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  addButton: {
    marginTop: "2rem",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Profile() {
  const navigate = useNavigate();

  const classes = useStyles();
  const [user, setUser] = useState(null);
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        fetchPlaces(currentUser.uid);
      } else {
        setUser(null);
        setPlaces([]);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);
  const handleLogout = () => {
    auth.signOut().then(() => {
      navigate("/");
    });
  };

  const fetchPlaces = async (uid) => {
    const db = getFirestore();
    const placesRef = collection(db, "users", uid, "places");
    const placesSnapshot = await getDocs(placesRef);

    const fetchedPlaces = placesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    setPlaces(fetchedPlaces);
  };

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  return (
    <div>
      <Box sx={{ position: "relative", minHeight: "10vh" }}>
        <Box className={classes.fixedContainer}></Box>

        <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
          <Box sx={{ width: 250 }} onClick={handleDrawerClose}>
            <List>
              <ListItem button onClick={() => navigate("/")}>
                <ListItemIcon>
                  <HomeIcon />
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Box className={classes.infoContainer}>
          <Avatar
            className={classes.avatar}
            src={user?.photoURL}
            alt={user?.displayName}
          />
          <Typography className={`${classes.title} ${classes.typography}`}>
            {user ? `${user.displayName}'s Profile` : "Loading..."}
          </Typography>
        </Box>
      </Box>
      <div style={{ display: "flex", justifyContent: "center" }}>
  <IconButton onClick={handleLogout}>
    Logout <ExitToAppIcon />
  </IconButton>
</div>

      <Summary />
      <BottomNav/>

    </div>
  );
}

export default Profile;
