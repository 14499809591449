import React, { useState, useEffect } from "react";
import PhotoUploader from "../PhotoUploader";
import { useParams } from "react-router-dom";
import {
  getStorage,
  ref,
  listAll,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { auth } from "../../firebase";
import {
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { doc, deleteDoc, setDoc } from "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { query, where, getDocs, collection } from "firebase/firestore";

function ParentComponent() {
  const { placeid } = useParams();
  const uid = auth.currentUser.uid;
  const storage = getStorage();
  const [imageUrls, setImageUrls] = useState([]);
  const fetchAllImages = async () => {
    const storageRef = ref(storage);
    const photosRef = ref(storageRef, `users/${uid}/places/${placeid}/photos`);
    const files = await listAll(photosRef);
    const urlPromises = files.items.map((item) => getDownloadURL(item));
    const urls = await Promise.all(urlPromises);
    setImageUrls(urls);
    return urls;
  };

  const handleDeleteImage = async (index) => {
    const storageRef = ref(storage);
    const photosRef = ref(storageRef, `users/${uid}/places/${placeid}/photos`);
    const file = (await listAll(photosRef)).items[index];
    const fileUrl = await getDownloadURL(file);

    // Delete the image from storage
    await deleteObject(file);

    // Delete the document from Firestore
    const firestore = getFirestore();
    const photoId = file.name;
    console.log(photoId);
    const photoCollectionRef = `users/${uid}/places/${placeid}/photos`;

    // Query Firestore for the document with the corresponding filename
    const q = query(
      collection(firestore, photoCollectionRef),
      where("filename", "==", photoId)
    );

    // Delete the document found
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Remove the image from the state
    const updatedImageUrls = [...imageUrls];
    updatedImageUrls.splice(index, 1);
    setImageUrls(updatedImageUrls);
  };

  useEffect(() => {
    fetchAllImages();
  }, []);

  return (
    <div>
      <PhotoUploader onImagesUploaded={fetchAllImages} />
    </div>
  );
}

function MageList() {
  const { placeid } = useParams();
  const uid = auth.currentUser.uid;
  const storage = getStorage();
  const [imageUrls, setImageUrls] = useState([]);
  const fetchAllImages = async () => {
    const storageRef = ref(storage);
    const photosRef = ref(storageRef, `users/${uid}/places/${placeid}/photos`);
    const files = await listAll(photosRef);
    const urlPromises = files.items.map((item) => getDownloadURL(item));
    const urls = await Promise.all(urlPromises);
    setImageUrls(urls);
    return urls;
  };

  const handleDeleteImage = async (index) => {
    const storageRef = ref(storage);
    const photosRef = ref(storageRef, `users/${uid}/places/${placeid}/photos`);
    const file = (await listAll(photosRef)).items[index];
    const fileUrl = await getDownloadURL(file);

    // Delete the image from storage
    await deleteObject(file);

    // Delete the document from Firestore
    const firestore = getFirestore();
    const photoId = file.name;
    console.log(photoId);
    const photoCollectionRef = `users/${uid}/places/${placeid}/photos`;

    // Query Firestore for the document with the corresponding filename
    const q = query(
      collection(firestore, photoCollectionRef),
      where("filename", "==", photoId)
    );

    // Delete the document found
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await deleteDoc(doc.ref);
    });

    // Remove the image from the state
    const updatedImageUrls = [...imageUrls];
    updatedImageUrls.splice(index, 1);
    setImageUrls(updatedImageUrls);
  };

  useEffect(() => {
    fetchAllImages();
  }, []);
  return (
    <div>
      <ImageList cols={3} gap={16} sx={{ marginBottom: 20 }}>
        {imageUrls.map((url, index) => (
          <ImageListItem key={index}>
            <img src={url} alt={`Uploaded-${index}`} loading="lazy" />
            <IconButton
              sx={{ position: "absolute", top: 0, right: 0 }}
              color=""
              onClick={() => handleDeleteImage(index)}
            >
              <DeleteIcon />
            </IconButton>
          </ImageListItem>
        ))}
      </ImageList>
      
    </div>
  );
}

export { MageList };

export default ParentComponent;
