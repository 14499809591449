// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore, collection } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDd9_Y2KeieMRgDDA7lly0-DSQcihNPgUU",
  authDomain: "place-b86ff.firebaseapp.com",
  projectId: "place-b86ff",
  storageBucket: "place-b86ff.appspot.com",
  messagingSenderId: "430703724501",
  appId: "1:430703724501:web:a1504cc14cb71eeffddf5c",
  measurementId: "G-SZVGDYW05N"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, firebaseConfig, storage, collection, analytics};
