import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db, auth } from "../../firebase";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { getDocs, query, where } from "firebase/firestore";
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import cityQuestions from "../../Components/QuestionsPage/questions";

import { doc, updateDoc, setDoc } from "firebase/firestore";
import PositionedMenu from "../../Components/PositionedMenu";
import BottomNav from "../../Components/Navbar/Navbar";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage:
      "url('https://media.cntraveller.com/photos/611bece34e09f53b43732f58/16:9/w_3200,h_1800,c_limit/beautiful.jpg')",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Times New Roman",
  },
  paper: {
    padding: theme.spacing(4),
    border: "1px solid #ccc",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
  },
  formItem: {
    margin: theme.spacing(2, 0),
  },
  label: {
    fontSize: "1.1rem",
    marginBottom: theme.spacing(1),
    display: "block",
  },
  input: {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
    border: "1px solid #ccc",
    borderRadius: "4px",
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1.5, 4),
    borderRadius: "4px",
    background: "#0077cc",
    color: "#fff",
    fontWeight: "bold",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    "&:hover": {
      background: "#005ea6",
    },
  },
  positionedMenu: {
    position: "fixed !important",
    top: 12,
    left: 12,
    zIndex: 1000,
    [theme.breakpoints.down("sm")]: {
      zIndex: 1000,
      position: "fixed !important",

      top: "calc(100% - 60px)", // Move the menu to the bottom on mobile
    },
  },
}));

function AddPlaceForm() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState(""); // Step 3.1: Add a new state to hold the image URL

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      // Check if a place with the same name already exists
      const querySnapshot = await getDocs(
        query(
          collection(db, "users", auth.currentUser.uid, "places"),
          where("name", "==", name)
        )
      );
      if (!querySnapshot.empty) {
        alert("Duplicate Place");

        return;
      }

      // Save the new place to Firebase Firestore under the current user's document
      const docRef = await addDoc(
        collection(db, "users", auth.currentUser.uid, "places"),
        {
          name,
          description,
          imageUrl, // Step 3.3: Save the image URL in the Firestore along with the other data
        }
      );
      // Add random questions to the new place under the new place's document
      const questions = cityQuestions
        .sort(() => 0.5 - Math.random())
        .slice(0, 5);
      const questionsData = questions.reduce((acc, questionText, index) => {
        acc[`question${index}`] = { text: questionText };
        return acc;
      }, {});

      await setDoc(
        doc(
          db,
          `users/${auth.currentUser.uid}/places/${docRef.id}/questions/questions`
        ),
        questionsData
      );

      // Navigate to the newly created page
      navigate(`/users/${auth.currentUser.uid}/places/${docRef.id}`);

      // Clear the form
      setName("");
      setDescription("");
    } catch (error) {
      console.error("Error saving new place:", error);
    }
  };

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <ToastContainer />
        <Paper className={classes.paper} sx={{ p: 4 }}>
          <Typography variant="h4" gutterBottom>
            Add a New Place
          </Typography>
          <form className={classes.form} onSubmit={handleSubmit}>
            <div className={classes.formItem}>
              <label className={classes.label} htmlFor="name">
                City Name
              </label>
              <input
                className={classes.input}
                id="name"
                type="text"
                required
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className={classes.formItem}>
              <label className={classes.label} htmlFor="description">
                Description of the City
              </label>
              <textarea
                className={classes.input}
                id="description"
                rows={1}
                required
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              ></textarea>
            </div>
            <Button className={classes.button} type="submit">
              Save
            </Button>
          </form>
        </Paper>
      </Container>
      <BottomNav/>
    </div>
  );
}
export default AddPlaceForm;
