// api.js

import axios from "axios";

const instance = axios.create({
  baseURL: "https://app.brendanlarkin.com",
});

export const getTripSummary = async (uid, placeid) => {
  try {
    console.log(uid, placeid);
    const response = await instance.post(
      "https://app.brendanlarkin.com/get_trip_summary",
      {
        uid: uid,
        placeid: placeid,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching trip summary:", error);
    return null;
  }
};
