import { useState, useEffect } from "react";
import { auth } from "../../firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import GoogleButton from "react-google-button";

function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const provider = new GoogleAuthProvider();

  const navigate = useNavigate();
  const handleGoogleSignIn = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // Handle successful sign in
        const user = result.user;
        console.log("Signed in user:", user);
        navigate("/home");
      })
      .catch((error) => {
        // Handle sign in error
        const errorMessage = error.message;
        console.error("Sign in error:", errorMessage);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Handle successful sign in
        const user = userCredential.user;
        console.log("Signed in user:", user);
        navigate("/home");
      })
      .catch((error) => {
        // Handle sign in error
        const errorMessage = error.message;
        console.error("Sign in error:", errorMessage);
      });
  };
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/home");
      }
    });

    return unsubscribe;
  }, [navigate]);
  const responseGoogle = (response) => {
    console.log(response);
    // Handle the Google Login response here
    // For example, you can extract user information from the response object
  };

  const theme = createTheme();
  const backgroundStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: -1, // Set a lower z-index to place the background behind the content
    overflow: "hidden", // Hide video overflow
  };

  const vid = "https://4kwallpapers.com/images/walls/thumbs_3t/4587.png";
  return (
    <div
      style={{
        position: "relative",
        minHeight: "100vh",
        margin: 0,
        padding: 0,
        marginBottom: "",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        backgroundImage:
          "url('https://images.pexels.com/photos/3694404/pexels-photo-3694404.jpeg?auto=compress&cs=tinysrgb&w=2000')",
      }}
    >
      <ThemeProvider theme={theme}>
        <GoogleButton onClick={handleGoogleSignIn}> </GoogleButton>
      </ThemeProvider>
    </div>
  );
}

export default SignIn;
