import React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

const AllImages = ({ imageUrls }) => {
  return (
    <ImageList
      sx={{
        width: '100%',
        height: 'auto',
        minHeight: '20vh',
      }}
      cols={4}
      gap={5}
    >
      {Array.isArray(imageUrls) &&
        imageUrls.map((url, index) => (
          <ImageListItem key={index}>
            <img
              src={url}
              alt={`Image ${index}`}
              loading="lazy"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          </ImageListItem>
        ))}
    </ImageList>
  );
};

export default AllImages;
